import { Injectable } from '@angular/core';
import {
    CanActivate, Router,
    ActivatedRouteSnapshot,
    RouterStateSnapshot
} from '@angular/router';

import { AuthentificationService } from '../shared/authentification.service';
import { UserApi } from '../models/user';

@Injectable()
export class RoleGuard implements CanActivate {
    constructor(private authService: AuthentificationService, private router: Router) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        const expectedGroups: Array<string> = route.data.expectedGroups;
        const user: UserApi = this.authService.getAuthUser();

        if (!user || !this.authService.isAuthenticated()) {
            return this.redirectToLogin();
        } else {
            let isAllowed = false;
            expectedGroups.forEach(role => {
                if (user.groups.indexOf(role) !== -1) {
                    isAllowed = true;
                }
            });
            if (!isAllowed) {
                return this.redirectToLogin();
            }
        }
        return true;
    }

    redirectToLogin(): boolean {
        // Store the attempted URL for redirecting
        this.authService.logout();
        this.router.navigate(['/auth/login']);
        return false;
    }

}
