export const navItems = [
  {
    name: `Liste d'activités`,
    url: '/activite',
    icon: 'fa fa-list'
  },
  {
    name: `Liste d'utilisateurs`,
    url: '/utilisateur',
    icon: 'fa fa-users',
    role: 'PA_ADMINISTRATEUR'
  }
];
