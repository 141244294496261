import {
  Injectable
} from '@angular/core';
import Sweetalert2 from 'sweetalert2';

@Injectable()
export class SweetAlertService {

  constructor() { }

  swal(title: string, message?: string) {
    return Sweetalert2(title, message);
  }

  confirm(options) {
    const defaultOptions = {
      showCancelButton: true,
      type: 'warning',
      text: 'Êtes-vous sûr ?',
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Confirmer',
      cancelButtonText: 'Annuler',
    };
    return Sweetalert2({...defaultOptions, ...options});
  }


  prompt(options) {
    const defaultOptions = {
      confirmButtonText: 'Submit',
      showCancelButton: true,
      input: 'text'
    };
    return Sweetalert2({...defaultOptions, ...options});
  }

  alert(options) {
    const defaultOptions = {
      confirmButtonText: 'OK',
      type: 'info'
    };
    return Sweetalert2({...defaultOptions, ...options});
  }

  question(options) {
    return this.alert({type: 'question', ...options});
  }

  success(options) {
    return this.alert({type: 'success', ...options});
  }

  warning(options) {
    return this.alert({type: 'warning', ...options});
  }

  error(options) {
    return this.alert({type: 'error', ...options});
  }

  info(options) {
    return this.alert({type: 'info', ...options});
  }

}
