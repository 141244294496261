import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthentificationComponent } from './core/+authentification/components/authentification/authentification.component';
import { AuthGuard } from './core/+authentification/guards/authentification.guard';
import { RoleGuard } from './core/+authentification/guards/role.guard';
import { MainLayoutComponent } from './core/layout/main-layout/main-layout.component';
export const routes: Routes = [
  {
    path: '',
    component: MainLayoutComponent,
    canActivate: [AuthGuard],
    data: {
      title: 'Accueil'
    },
    children: [
      {
        path: 'activite',
        loadChildren: './features/activity/activity.module#ActivityModule',
        data: {
          expectedGroups: ['PA_ADMINISTRATEUR', 'PA_CHEF_ATELIER']
        }
      },
      {
        path: 'utilisateur',
        loadChildren: './features/user/user.module#UserModule',
        canActivate: [RoleGuard],
        data: {
          expectedGroups: ['PA_ADMINISTRATEUR']
        }
      }
    ]
  },
  { path: 'auth', component: AuthentificationComponent, loadChildren: './core/+authentification/authentification.module#AuthentificationModule' },
  { path: '**', redirectTo: 'auth/login' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
