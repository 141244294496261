import { Router } from '@angular/router';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpHeaders } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';

import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthentificationService } from './authentification.service';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

    constructor(public injector: Injector) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const auth = this.injector.get(AuthentificationService);
        const token = auth.getToken();

        if (token) {
            const headers = new HttpHeaders({
                Authorization: `Bearer ${token}`,
                'X-Auth-Token': token
              });

            request = request.clone({headers});
        }
        return next.handle(request).pipe(
            catchError(x => this.handleAuthError(x))
        );
    }

    private handleAuthError(error: HttpErrorResponse): Observable<any> {

        if (error.status === 401 || error.status === 403) {
            const auth = this.injector.get(AuthentificationService);
            auth.logout();
            const router = this.injector.get(Router);
            router.navigate(['/auth/login']);
        }
        return throwError(error);
    }
}
