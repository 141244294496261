import { Component, Input } from '@angular/core';
import { navItems } from './_nav';
import { AuthentificationService } from '@app/core/+authentification/shared/authentification.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-dashboard',
  templateUrl: './main-layout.component.html',
  styleUrls: ['./main-layout.component.scss']
})
export class MainLayoutComponent {
  public navItems;
  public sidebarMinimized = true;
  private changes: MutationObserver;
  public element: HTMLElement = document.body;
  constructor(private _router: Router,
              private _authService: AuthentificationService) {

    this.changes = new MutationObserver((mutations) => {
      this.sidebarMinimized = document.body.classList.contains('sidebar-minimized');
    });

    this.changes.observe(<Element>this.element, {
      attributes: true
    });

    this._authService.loggedInSub.subscribe(loggedIn => {
      if (loggedIn) {
        const user = this._authService.getAuthUser();
        this.navItems = navItems;
        if (user.groups.indexOf('PA_ADMINISTRATEUR') === -1) {
          this.navItems = this.navItems.filter(n => n.role !== 'PA_ADMINISTRATEUR');
        }
      }
    });

  }

  /**
   * Logout user and redirect
   */
  onLogout(): void {
    this._authService.logout();
    this._router.navigate(['/auth/login']);
  }
}
