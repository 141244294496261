import { TemplateRef, ViewContainerRef, Input, Directive } from '@angular/core';

import { AuthentificationService } from './authentification.service';

@Directive({
    selector: '[appRoles]',
})
export class AppRolesDirective {

    constructor(private _templateRef: TemplateRef<any>,
        private _viewContainer: ViewContainerRef,
        private authService: AuthentificationService) {

    }

    @Input() set appRoles(allowedRoles: Array<string>) {
        const user = this.authService.getAuthUser();
        if (!user) {
            this.clearContent();
        } else if (!user.groups) {
            this.authService.logout();
        } else {
            const userRoles: string[] = user.groups;

            let isAllowed = false;
            allowedRoles.forEach(role => {
                if (userRoles.indexOf(role) !== -1) {
                    isAllowed = true;
                }
            });
            if (!isAllowed) {
                this.clearContent();
            } else {
                this._viewContainer.createEmbeddedView(this._templateRef);
            }
        }

    }

    clearContent() {
        this._viewContainer.clear();
    }

}



