import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { routing } from './authentification.routing';
import { AuthentificationComponent } from './components/authentification/authentification.component';
import { AppRolesDirective } from './shared/roles.directive';

@NgModule({
  imports: [
    CommonModule,
    routing
  ],
  declarations: [AuthentificationComponent, AppRolesDirective],
  exports: [AppRolesDirective]
})
export class AuthentificationModule { }
