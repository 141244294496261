import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable, Subject, BehaviorSubject } from 'rxjs';
import { KeystoneAuthBody } from '../models/keystone-auth-body';
import { UserApi } from '../models/user';
import { NotificationsService } from '../../services/notifications.service';

@Injectable()
export class AuthentificationService {
  private isLoggedIn = false;
  public loggedInSub: Subject<boolean> = new BehaviorSubject<boolean>(this.isLoggedIn);
  public loggInSub: Subject<boolean> = new BehaviorSubject<boolean>(null);

  public redirectUrl: string;

  constructor(
    private httpClient: HttpClient,
    private router: Router,
    private notificationService: NotificationsService
  ) {}

  /**
   * Retourne les informations de l'utilisateur
   * @return UserApi
   */
  public getAuthUser(): UserApi {
    let user: UserApi;
    try {
      user = JSON.parse(localStorage.getItem('auth-user')) || null;
    } catch (error) {
      user = null;
      this.logout();
      this.router.navigate(['/auth/login']);
    }
    return user;
  }

  /**
   * Définis le user
   * @param user
   */
  public setAuthUser(user: string): void {
    localStorage.setItem('auth-user', JSON.stringify(user));
  }

  /**
   * Retourne le token utilisateur
   * @return string
   */
  public getToken(): string {
    return localStorage.getItem('token');
  }

  /**
   * Définis le token
   * @param token
   */
  private setToken(token: string): void {
    localStorage.setItem('token', token);
  }

  /**
   * Retourne le status de connexion de l'utilisateur
   * @return boolean
   */
  public isAuthenticated(): boolean {
    const token = this.getToken();
    if (token !== null && !this.isLoggedIn) {
      // re-log from storage on f5 or app reload
      this.isLoggedIn = true;
      this.loggedInSub.next(this.isLoggedIn);
    }
    return token !== null;
  }

  /**
   * Construit le body pour la requête d'authentification
   * @param username
   * @param password
   * @return KeystoneAuthBody
   */
  private buildBody(username, password): KeystoneAuthBody {
    const data = {
      auth: {
        identity: {
          methods: ['password'],
          password: {
            user: {
              domain: {
                name: 'Default'
              },
              name: username,
              password: password
            }
          }
        }
      }
    };
    return data;
  }

  /**
   * Méthode d'authentification
   * @param username
   * @param password
   */
  public login(username: string, password: string): void {
    const body: KeystoneAuthBody = this.buildBody(username, password);

    const headers = new HttpHeaders().set('Content-Type', 'application/json');

    this.httpClient
      .post('/loginV3', JSON.stringify(body), {
        headers,
        withCredentials: true,
        observe: 'response'
      })
      .subscribe(
        (response: any) => {
          this.parseAuthResponse(response);
          this.getUserInfos(response.body.token.user.id);
        },
        response => {
          this.displayError('Identifiant ou mot de passe invalide');
          this.loggInSub.next(false);
        }
      );
  }

  /**
   * Récupère les informations de l'utilisateur depuis le referentiel-utilisateur
   * @param userId
   */
  private getUserInfos(userId: string): void {
    this.httpClient.get(`api/users/${userId}`).subscribe(
      (response: any) => {
        this.setAuthUser(response);
        this.isLoggedIn = true;
        this.loggedInSub.next(this.isLoggedIn);
      },
      response => {
        this.displayError('Impossible de contacter le serveur');
        this.loggedInSub.next(false);
      }
    );
  }

  /**
   * Affiche un message d'erreur
   * @param message
   */
  private displayError(message: string): void {
    this.notificationService.error(message);
  }

  /**
   * Extrait les données de la réponse d'authorizo
   * @param response Reponse HTTP
   */
  private parseAuthResponse(response): void {
    const token = response.headers.get('X-Subject-Token') || response.headers.get('X-Auth-Token');
    // token = token;

    const payload = response.body;
    const user = {
      id: payload.token.user.id,
      name: payload.token.user.name
    };
    localStorage.setItem('user', JSON.stringify(user));

    this.setToken(token);
  }

  /**
   * Supprime les données d'authentifiaction du localstorage
   */
  public cleanStorage(): void {
    localStorage.removeItem('token');
    localStorage.removeItem('auth-user');
  }

  /**
   * Déclenche la suppression des données d'authentifiaction
   * Notifie l'app du changement d'etat de l utilisateur
   */
  public logout(): void {
    this.cleanStorage();
    this.isLoggedIn = false;
    this.loggedInSub.next(this.isLoggedIn);
  }

  /**
   * On conserve les données utilisateur mais on supprime le token
   * Force le user en cours à se reconnecter
   */
  public lock(): void {
    localStorage.removeItem('token');
    this.isLoggedIn = false;
    this.loggedInSub.next(this.isLoggedIn);
  }

  /**
   * Demande de ré-initialisation de mot de passe
   * @param {*} userId
   * @return Observable<any>
   */
  public resetPassword(userId: string): Observable<any> {
    return this.httpClient.post(`/users/${userId}/_resetpassword`, null);
  }

  /**
   * Determine si l'utilisateur fait partie du groupe
   * @param group
   * @return boolean
   */
  public isInGroup(group: string): boolean {
    const user: UserApi = this.getAuthUser();
    const isGroupeFound: string = user && user.groups ? user.groups.find((grp: string) => grp === group) : undefined;
    return isGroupeFound !== undefined;
  }
}
