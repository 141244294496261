import {Injectable} from '@angular/core';
import { TurboNotifyPopupService } from '@app/core/notifications/turbo-notify-popup/services/turbo-notify-popup.service';


declare var $: any;

export enum NotificationType {
  SUCCESS = 'success',
  ERROR = 'error'
}

export enum NotificationPosition {
  TOP = 'top',
  BOTTOM = 'bottom'
}

export interface NotificationOptions {
  message: string;
  type?: NotificationType,
  position?: NotificationPosition,
  duration?: string,
  location?: string
}

@Injectable()
export class NotificationsService {

  private defaultOptions: NotificationOptions = {
    message: '',
    type: NotificationType.SUCCESS,
    duration: '4000',
    location: 'main',
    position: NotificationPosition.TOP
  }

  constructor(private _notify: TurboNotifyPopupService) { }

  /**
   * Affiche un message de notification à l'écran en utilisant turbo-notify-popup
   * @param opt
   */
  show(opt: NotificationOptions): void {
    const options = {...this.defaultOptions, ... opt, ...{duration : 6000}};

   this._notify.show(
       options.message,
       { position: options.position , duration: options.duration, type: options.type });
  }

  /**
   * Affiche un message de notification de type success
   * @param message
   */
  success(message: string): void {
    const options: NotificationOptions = {...this.defaultOptions, ... {message: message }};
    this.show(options);
  }

  /**
   * Affiche un message de notification de type error
   * @param message
   */
  error(message: string): void {
    const options: NotificationOptions = {...this.defaultOptions, ... {message: message, type: NotificationType.ERROR, duration: '6000'}};
    this.show(options);
  }

  /**
   * Ouvre une petite boite de dialogue
   * @param data
   * @param cb
   */
  smallBox(data, cb?): void {
    $.smallBox(data, cb);
  }

  /**
   * Ouvre une grande boite de dialogue
   * @param data
   * @param cb
   */
  bigBox(data, cb?): void {
    $.bigBox(data, cb);
  }

  /**
   * Ouvre une boite de dialogue
   * @param data
   * @param cb
   */
  smartMessageBox(data, cb?): void {
    $.SmartMessageBox(data, cb);
  }

}
