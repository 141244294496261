import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';

import { TurboNotifyPopupModule } from './notifications/turbo-notify-popup/turbo-notify-popup.module';
import { NotificationsService } from './services/notifications.service';
import { HttpGenericService } from './services/http.generic.service';
import { ErrorService } from './services/error.service';
import { SweetAlertService } from './services/sweetalert2.service';
import { AuthentificationModule } from '@app/core/+authentification/authentification.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AuthentificationService } from '@app/core/+authentification/shared/authentification.service';
import { RouterModule } from '@angular/router';
import { AuthGuard } from '@app/core/+authentification/guards/authentification.guard';
import { RoleGuard } from '@app/core/+authentification/guards/role.guard';

@NgModule({
  declarations: [
  ],
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    ReactiveFormsModule,
    AuthentificationModule,
    TurboNotifyPopupModule,
    HttpClientModule
  ],
  providers: [
    AuthentificationService,
    AuthGuard,
    RoleGuard,
    NotificationsService,
    HttpGenericService,
    ErrorService,
    SweetAlertService
  ],
  exports: [
  ]
})
export class CoreModule { }
