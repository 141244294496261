import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TurboNotifyPopupService } from './services/turbo-notify-popup.service';
import { TurboNotifyPopupComponent } from './components/turbo-notify-popup-component';
import { ComponentInjectService } from './services/component-inject.service';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  declarations: [
    TurboNotifyPopupComponent
  ],
  exports: [],
  providers: [
    TurboNotifyPopupService,
    ComponentInjectService
  ],
  entryComponents: [TurboNotifyPopupComponent]
})
export class TurboNotifyPopupModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: TurboNotifyPopupModule,
    };
  }
}
