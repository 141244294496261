import { Router } from '@angular/router';
import { Injectable } from '@angular/core';

import { Subject } from 'rxjs/internal/Subject';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { SweetAlertService } from '@app/core/services/sweetalert2.service';
import { HttpErrorResponse } from '@angular/common/http';
import { AuthentificationService } from '@app/core/+authentification/shared/authentification.service';
import { NotificationsService, NotificationType } from '@app/core/services/notifications.service';

export enum ErrorStatus {
  Critical = 0,
  Normal
}

export enum ErrorDisplayMode {
  Console = 0,
  Modal,
  Subject,
  Alert
}

export interface ErrorMessage {
  message: string;
  errorStatus: ErrorStatus;
}

interface NotificationErrorMessage {
  message?: string;
  statusText?: string;
  error?: {
    message?: string;
    validationError: Array<ValidationsError>;
  };
}

interface ValidationsError {
  field: string;
  message: string;
}

@Injectable()
export class ErrorService {
  public errorSub: Subject<ErrorMessage> = new BehaviorSubject<ErrorMessage>(null);

  constructor(
    private _authService: AuthentificationService,
    private _router: Router,
    private _sweetAlertService: SweetAlertService,
    private _notificationModalService: NotificationsService
  ) {}

  /**
   * Methode public permettant d'afficher une erreur et de logout l'utilisateur si besoin.
   * @param {string | NotificationErrorMessage} message : le message
   * @param {ErrorStatus} errorStatus : si le status est Critical alors le user est deconnecté puis redirigé vers la page de login
   * @param {ErrorDisplayMode} [errorDisplayMode=0] : mode d affichage de l'erreur, par défaut console.log()
   */
  public error(
    message: string | NotificationErrorMessage,
    errorStatus: ErrorStatus,
    errorDisplayMode: ErrorDisplayMode = 0,
    httpError: HttpErrorResponse = null
  ): void {
    if (errorStatus === ErrorStatus.Critical) {
      this._authService.logout();
      this._router.navigate(['auth/login']);
    }
    this.displayError(message, errorStatus, errorDisplayMode, httpError);
  }

  /**
   *  Affichage de l'erreur selon ErrorDisplayMode
   * @private
   * @param {*} message
   * @param {ErrorStatus} errorStatus
   * @param {ErrorDisplayMode} errorDisplayMode
   */
  private displayError(
    message: string | NotificationErrorMessage,
    errorStatus: ErrorStatus,
    errorDisplayMode: ErrorDisplayMode,
    httpError: HttpErrorResponse
  ): void {
    const formatedMessage = this.buildMessage(message, httpError);
    switch (errorDisplayMode) {
      case ErrorDisplayMode.Console: {
        console.error(formatedMessage);
        break;
      }
      case ErrorDisplayMode.Modal: {
        this._sweetAlertService.error({ text: formatedMessage });
        break;
      }
      case ErrorDisplayMode.Subject: {
        this.errorSub.next({
          message: formatedMessage,
          errorStatus: errorStatus
        });
        break;
      }
      case ErrorDisplayMode.Alert: {
        this.displayAlert(formatedMessage);
      }
    }
  }

  /**
   * Affiche le message sous forme de Toastr
   *
   * @private
   * @param {*} message
   * @memberof ErrorService
   */
  private displayAlert(message: string): void {
    this._notificationModalService.show({
      message: message,
      type: NotificationType.ERROR
    });
  }

  /**
   * Construit et retourne le message sous forme de string
   * @private
   * @param {(string | NotificationErrorMessage)} message
   * @returns {string}
   * @memberof ErrorService
   */
  private buildMessage(message: string | NotificationErrorMessage, httpError: HttpErrorResponse): string {
    let errorMessage = '';

    if (typeof message === 'string' && httpError === null) {
      errorMessage = message;

    } else if (httpError.error && httpError.error.message) {
        errorMessage = `${message} ${httpError.error.message}`;
    } else {
      errorMessage = `${message} Erreur inconnue`;
    }

    return errorMessage;
  }
}
