import { Component, OnInit, ViewContainerRef } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';

@Component({
  // tslint:disable-next-line
  selector: 'body',
  template: '<router-outlet></router-outlet>'
})
export class AppComponent implements OnInit {
  constructor(
      private _viewContainerRef: ViewContainerRef,
      private _router: Router,
      private _activatedRoute: ActivatedRoute,
      private _titleService: Title
  ) {}

  ngOnInit() {
    console.log('Pointage Atelier V1.0.0');
      // this._router.events.pipe()
      //   .filter((event) => event instanceof NavigationEnd)
      //   .map(() => this._activatedRoute)
      //   .map((route) => {
      //     while (route.firstChild) {
      //       route = route.firstChild;
      //     }
      //     return route;
      //   })
      //   .filter((route) => route.outlet === 'primary')
      //   .mergeMap((route) => route.data)
      //   .subscribe((evt) => {
      //     this._titleService.setTitle(`PA - ${event['pageTitle']}`);
      //     if (!(evt instanceof NavigationEnd)) {
      //       return;
      //     }
      //     window.scrollTo(0, 0);
      // });

    this._router.events
      .subscribe((evt) => {
        if (!(evt instanceof NavigationEnd)) {
          return;
        }
        window.scrollTo(0, 0);
      });

    this._router.navigateByUrl('activite');
  }
}
