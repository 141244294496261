import {
  Injectable,
  ComponentRef,
} from '@angular/core';
import { TurboNotifyPopupComponent } from '../components/turbo-notify-popup-component';
import { TurboNotifyPopup } from '../models/turbo-notify-popup';
import { ComponentInjectService } from './component-inject.service';

@Injectable()
export class TurboNotifyPopupService {

  constructor(private compInject: ComponentInjectService) {
  }

  private defaultConfigVar: TurboNotifyPopup = new TurboNotifyPopup('bottom', 3000, 'error', 'body', false, '');
  private activeNotificationCompRef: ComponentRef<any>;
  private cleartime: number;
  private clearhide: number;

  public defaultConfig(configObject: Object): void {
    this.setConfig(configObject, this.defaultConfigVar);

  }

  public destroy(): void {
    clearTimeout(this.cleartime);
    clearTimeout(this.clearhide);
    if (this.activeNotificationCompRef) {
      this.compInject.destroyComponent(this.activeNotificationCompRef);
      this.activeNotificationCompRef = null;
    }
  }

  public show(notifictionText: string, notificationConfig?: Object): void {
    this.destroy();
    const config: TurboNotifyPopup = new TurboNotifyPopup(this.defaultConfigVar.position, this.defaultConfigVar.duration, this.defaultConfigVar.type, this.defaultConfigVar.location, this.defaultConfigVar.sticky, notifictionText);
    if (notificationConfig !== undefined && notificationConfig != null) {
      this.setConfig(notificationConfig, config);
    }
    if (config.location === 'body') {
      this.activeNotificationCompRef = this.compInject.appendComponentToBody(TurboNotifyPopupComponent, config, document.querySelector('body'));
    } else {
      this.activeNotificationCompRef = this.compInject.appendComponent(TurboNotifyPopupComponent, config, document.querySelector(config.location));
    }

    this.activeNotificationCompRef.instance.fade = 'show';

    if (!this.activeNotificationCompRef.instance.sticky) {
      this.cleartime = window.setTimeout(() => {
        this.activeNotificationCompRef.instance.fade = 'hide';
        this.clearhide = window.setTimeout(() => {
          this.destroy();
        }, 700);
      }, config.duration);
    } else {
      this.activeNotificationCompRef.instance.destroyComponent.subscribe((value) => {
        this.activeNotificationCompRef.instance.fade = 'hide';
        this.clearhide = window.setTimeout(() => {
          this.destroy();
        }, 700);
      });
    }
  }

  private setConfig(configObject: Object, targetObject: TurboNotifyPopup): void {
    const props = Object.getOwnPropertyNames(configObject);
    for (const prop of props) {
      (<any>targetObject)[prop] = (<any>configObject)[prop];
    }

  }
}
